import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import Karta from "../components/Karta";
import SEO from "../components/SEO";
import BanerPageLayout from "../layout/BanerPageLayout";
import {
  FontLabel,
  FontSizeDefault,
  FontSizeHeader,
  Label,
} from "../styles/shared";
import { Cennik, CennikNode } from "../types/cennik-types";

const CennikContainer = styled.article`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
  gap: 1em;
`;
const Header = styled.h1`
  ${Label}
  ${FontSizeHeader}
  align-self: center;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
`;

const Table = styled.table`
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
  caption {
    ${FontLabel}
    text-align: left;
    margin-bottom: 0.5em;
    padding-bottom: 0.5em;
    caption-side: top;
    border-bottom: 1px solid #000;
  }
  th {
    font-family: "work sans";
    ${FontSizeDefault}
    text-align: right;
    width: 1%;
    white-space: nowrap;
    font-weight: 400;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    font-size: 0.8em;

    &:first-child {
      width: 100%;
    }
    /* add border between columns with data-pricecolumn */
    &[data-pricecolumn] {
      /* if after element with [data-pricecolumn] */
      + [data-pricecolumn] {
        border-left: 1px solid #000;
        padding-left: 1rem;
      }
    }
  }
  td {
    font-family: "open sans";
    ${FontSizeDefault}
    margin-bottom: 0.5rem;
    text-align: right;
    white-space: nowrap;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    &:first-child {
      white-space: normal;
      text-align: left;
    }
    &[data-pricerow] {
      + [data-pricerow] {
        border-left: 1px solid #000;
        padding-left: 1rem;
      }
    }
  }
`;

const CennikTable = ({ cennik }: { cennik: Cennik }) => {
  const hasHead = cennik.nazwyKolumn && cennik.nazwyKolumn.length > 0;
  return (
    <Table>
      <caption>{cennik.nazwa}</caption>
      {hasHead && (
        <thead>
          <tr>
            <th>{/* nazwa produktu, bez nagłówka */}</th>
            {cennik.nazwyKolumn!.map((nazwaKolumny) => (
              <th data-pricecolumn key={nazwaKolumny}>
                {nazwaKolumny}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {cennik.produkty.map(({ id, cena, nazwaZabiegu }) => (
          <tr key={id}>
            <td>{nazwaZabiegu}</td>
            {cena.map((c) => (
              <td data-pricerow key={c}>
                {c}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const CennikPage = () => {
  const {
    allContentfulStronaCennik: { nodes },
  } = useStaticQuery(graphql`
    query {
      allContentfulStronaCennik(limit: 1) {
        nodes {
          baner {
            gatsbyImageData
            description
            contentful_id
            __typename
          }
          seoTytulKarty
          seoOpisKarty
          cennik {
            nazwa
            id
            nazwyKolumn
            produkty {
              id
              cena
              nazwaZabiegu
            }
          }
        }
      }
    }
  `);
  const node: CennikNode = nodes[0];
  return (
    <React.Fragment>
      <SEO {...node} />

      <BanerPageLayout baner={node.baner}>
        <Karta>
          <Header>CENNIK</Header>
          <CennikContainer>
            {node.cennik.map((cennik) => (
              <CennikTable key={cennik.id} cennik={cennik} />
            ))}
          </CennikContainer>
        </Karta>
      </BanerPageLayout>
    </React.Fragment>
  );
};

export default CennikPage;
